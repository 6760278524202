import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/home' },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/index.vue')
  },
  {
    path: '/productdetails/:id',
    name: 'productdetails',
    component: () => import('../views/product/details.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/newsdetails/:id',
    name: 'newsdetails',
    component: () => import('../views/news/details.vue')
  },
  {
    path: '/reponses',
    name: 'reponses',
    component: () => import('../views/reponses/index.vue')
  },
  {
    path: '/reponsesdetails/:id',
    name: 'reponsesdetails',
    component: () => import('../views/reponses/details.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/team/index.vue')
  },
  {
    path: '/teamdetails/:id',
    name: 'teamdetails',
    component: () => import('../views/team/details.vue')
  },


  
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to,from,next)=>{
  window,scrollTo(0,0)
})

export default router
