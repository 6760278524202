<template>
  <el-header style="padding: 0;background-color: white;position: fixed;z-index: 9999;left: 0;right: 0;top: 0;"
    height="80px">
    <div class="box">
      <div class="weap">
        <div class="logo">
          <a href="javascript:;" title="logo">
            <img src="../assets/a.png" alt="">
          </a>
        </div>
        <div class="right">
          <div class="qm-nav">
            <ul class="navul">
              <li class="nl on" @click="cursorchange($event)">
                <router-link :to="{ name: 'home' }" class="alink">首页</router-link>
              </li>
              <li class="nl" @click="cursorchange($event)">
                <router-link :to="{ name: 'product' }" class="alink">产品中心</router-link>
              </li>
              <li class="nl" @click="cursorchange($event)">
                <router-link :to="{ name: 'reponses' }" class="alink">精选问答</router-link>
              </li>
              <li class="nl" @click="cursorchange($event)">
                <router-link :to="{ name: 'news' }" class="alink">行业资讯</router-link>
              </li>
              <li class="nl" @click="cursorchange($event)">
                <router-link :to="{ name: 'team' }" class="alink">服务团队</router-link>
              </li>
              <li class="nl" @click="cursorchange($event)">
                <router-link :to="{ name: 'about' }" class="alink">关于我们</router-link>
              </li>
            </ul>
          </div>
          <router-link :to="{ name: 'team' }" class="btn">我要咨询</router-link>
        </div>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  methods: {
    cursorchange(e) {
      let arr = document.querySelectorAll('.el-header .nl')
      arr.forEach(item => {
        item.classList.remove('on')
      })
      e.currentTarget.classList.add('on')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 80px;
  overflow: hidden;

  .weap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .right {
      display: flex;
      align-items: center;

      .btn {
        width: 103px;
        height: 34px;
        background: #1E91FA;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        line-height: 34px;
        font-weight: normal;
        border: none;
      }
    }
  }
}

.nl {
  float: left;
  position: relative;

  &.on,
  &:hover {
    .alink {
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 16px;
      font-weight: normal;
      color: #000000;
    }
  }

  .alink {
    display: block;
    font-size: 16px;
    padding: 0 35px;
    text-align: center;
    line-height: 90px;
    color: #777;
    position: relative;
    z-index: 2;
  }
}
</style>