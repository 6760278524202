<template>
  <div id="app">
    <el-container direction="vertical" style="min-height: 100vh">
      <Header></Header>
      <el-main style="padding: 0;margin-top: 80px;">
        <router-view />
      </el-main>
      <Footer></Footer>
    </el-container>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import Header from './components/header.vue';
import Footer from './components/footer.vue';
export default {
  components: { Header, Footer }
}
</script>
