<template>
  <el-footer style="padding: 0" height="450">
    <div class="box">
      <div class="weap">
        <div class="main">
          
          <div class="rightdom">
            <ul>
              <li><span>产品中心</span></li>
              <li><router-link class="A" to="/">H5支付</router-link><router-link class="A" to="/">网关支付</router-link></li>
              <li><router-link class="A" to="/">APP支付</router-link><router-link class="A" to="/">跨境支付</router-link></li>
              <li><router-link class="A" to="/">PC支付</router-link><router-link class="A" to="/">待付款</router-link></li>
              <li><router-link class="A" to="/">快捷支付</router-link><router-link class="A" to="/">聚合支付</router-link></li>  
            </ul>
            <ul>
              <li><span>网站导航</span></li>
              <li><router-link to="/">服务中心</router-link></li>
              <li><router-link to="/">精选问答</router-link></li>
              <li><router-link to="/">关于我们</router-link></li>
              <li><router-link to="/">招贤纳士</router-link></li>
              <li><router-link to="/">联系我们</router-link></li>
            </ul>
            <ul>
              <li><span>联系我们</span></li>
              <li>热线：400-028-8850</li>
              <li style="display: flex;">地址：<p style="width: 182px;">广东省深圳市龙华区龙胜时代大厦9楼903</p></li>
            </ul>
          </div>
          <div class="leftdom">
            <div class="vx" style="margin-right: 53px;">
              <img src="../../public/img/gzh.png" alt="">
              <p>微信公众号</p>
            </div>
            <div class="xcx">
              <img src="../../public/img/xcx.png" alt="">
              <p>微信小程序</p>
            </div>
          </div>
        </div>
        
      </div>
      <div class="down">
          <span class="right">copyright©2020  版权所有:深圳二维码收款有限公司 
          </span>
          <span class="left">粤公网安备21068502000020 粤ICP备02000688号</span>
        </div>
    </div>
  </el-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  background-color: #00162f;
 color: #999999;
  font-family: 思源黑体;

  .weap {
    width: 70%;
    margin: 0 auto;

    .main {
      padding: 49px 0 70px;
      width: 100%;
      overflow: hidden;

      .leftdom {
        float: right;
        display: flex;
        width: 30%;
        justify-content: center;
        >div{
          p{
            margin-top: 14px;
            text-align: center;
          }
        }
      }

      .rightdom {
        float: left;
        display: flex;
        justify-content: space-between;
        width: 60%;

        ul {
          li {
            font-weight: normal;
            line-height: 14px;
            font-size: 14px;
            margin-bottom: 16px;
            .A{width: 68px;display: inline-block;}
            span {
              font-weight: normal;
              font-size: 18px;
              line-height: 18px;
              margin-bottom: 16px;
            }

          }
        }
      }
    }

    
  }
  .down {
    height: 60px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999999;
      justify-content: center;
      font-family: 思源黑体;
      background: #001021;
      .right{
        margin-right: 30px;
      }
    }
}

</style>